import { AgmCoreModule } from '@agm/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatCardModule, MatCheckboxModule,
  MatDatepickerModule, MatDialogModule, MatExpansionModule,
  MatFormFieldModule, MatInputModule, MatNativeDateModule,
  MatPaginatorModule, MatProgressSpinnerModule, MatSelectModule,
  MatSnackBarModule, MatTableModule, MatTabsModule
} from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { jqxCalendarComponent } from './../../../node_modules/jqwidgets-framework/jqwidgets-ts/angular_jqxcalendar';
import { jqxTreeGridComponent } from './../../../node_modules/jqwidgets-framework/jqwidgets-ts/angular_jqxtreegrid';
import { CalenderComponent } from './components/app-calender/calender.component';
import { AppDialogComponent } from './components/app-dialog/app-dialog.component';
import { EnlargeMapComponent } from './components/map-view/enlarge-map/enlarge-map.component';
import { MapViewComponent } from './components/map-view/map-view.component';
// import { AppLogoComponent } from './components/app-logo/app-logo.component';
// import { AuthorityStatisticsComponent } from './components/app-statistics/authority-statistics/authority-statistics.component';
// import { OwnerStatisticsComponent } from './components/app-statistics/owner-statistics/owner-statistics.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ViewMoreDirective } from './directives/view-more.directive';
import { FetchBestContactFromPropertyPipe } from './pipes/fetch-best-contact-from-property.pipe';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { InspectionSnapshotComponent } from '../inspector/shared/inspection-snapshot/inspection-snapshot.component';
import { InspectorDetailsComponent } from './components/inspector-details/inspector-details.component';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { ParentModule } from './components/parent.component';
import { LoaderComponent } from '../components/shared/loader/loader.component';
import { FormFocusInvalidDirective } from './directives/form-focus-invalid/form-focus-invalid.directive';
import { DeleteRequestDialogComponent } from './components/delete-request-dialog/delete-request-dialog.component';
import { environment } from '../../environments/environment';
import { AssignPropertyDialogComponent } from './components/assign-property-dialog/assign-property-dialog.component';
import { PropertyNotificationComponent } from './components/property-notification/property-notification.component';

@NgModule({
  declarations: [
    PageNotFoundComponent,
    jqxCalendarComponent,
    CalenderComponent,
    jqxTreeGridComponent,
    AppDialogComponent,
    MapViewComponent,
    EnlargeMapComponent,
    FetchBestContactFromPropertyPipe,
    ViewMoreDirective,
    ConfirmationDialogComponent,
    InspectionSnapshotComponent,
    InspectorDetailsComponent,
    FormFocusInvalidDirective,
    DeleteRequestDialogComponent,
    AssignPropertyDialogComponent,
    PropertyNotificationComponent,
    ],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatPaginatorModule,
    RouterModule,
    MatTabsModule,
    MatTableModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    // MatNativeDateModule,
    MatMomentDateModule,
    ReactiveFormsModule,
    MatMenuModule,
    FormsModule,
    DragDropModule,
    HttpClientModule,
    RouterModule,
    MatSnackBarModule,
    MatCardModule,
    MatProgressSpinnerModule,
    AgmCoreModule.forRoot({
      apiKey: environment.geoLocationMapAPIKey  // Provide api Key after registering google maps for production purpose :)
    }),
    PortalModule,
    MatExpansionModule,
    MatCheckboxModule,
    ParentModule,
    MatTooltipModule,
  ],
  exports: [
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatTooltipModule,
    MatListModule,
    MatButtonModule,
    PageNotFoundComponent,
    jqxCalendarComponent,
    CalenderComponent,
    ReactiveFormsModule,
    MatTabsModule,
    MatTableModule,
    MatDialogModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    // MatNativeDateModule,
    CalenderComponent,
    jqxTreeGridComponent,
    AppDialogComponent,
    ConfirmationDialogComponent,
    MapViewComponent,
    EnlargeMapComponent,
    MatExpansionModule,
    FetchBestContactFromPropertyPipe,
    EnlargeMapComponent,
    InspectionSnapshotComponent,
    InspectorDetailsComponent,
    ParentModule,
    ViewMoreDirective,
    FormFocusInvalidDirective,
    AssignPropertyDialogComponent, 
    PropertyNotificationComponent
  ],
  entryComponents: [
    EnlargeMapComponent,
    ConfirmationDialogComponent,
    EnlargeMapComponent,
    AssignPropertyDialogComponent
  ],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }

  ]
})
export class SharedModule { }
