import { DatePipe, Location } from "@angular/common";
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  ViewChild,
  AfterViewChecked,
  ElementRef,
  OnChanges,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { AuthorityService } from "../../authority/shared/services/authority.service";
import {
  HeadingQuestion,
  InspectionData,
  InspectionDTO,
} from "../../core/models/inspection/inspection.model";
import { InspectionService } from "../../core/services/inspection/inspection.service";
import {
  MAT_RADIO_DEFAULT_OPTIONS,
  MatRadioChange,
} from "@angular/material/radio";
import { LoggerService } from "../../core/services/logger/logger.service";
@Component({
  selector: "app-questions",
  templateUrl: "./questions.component.html",
  styleUrls: ["./questions.component.scss"],
  providers: [
    DatePipe,
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: "accent" },
    },
  ],
})
export class QuestionsComponent implements OnInit, OnDestroy, AfterViewChecked, OnChanges {
  @Input() viewReportData: any;
  @Input() reviewInspectionReport: any;
  @Input() questions: Array<HeadingQuestion>;
  @Input() qtd: Array<any>;
  @Input() inspectionResult: InspectionDTO;
  @Input() showInspectionQuestions: boolean;
  @Input() inspectionDataforSecondPage: InspectionData;
  @Input() images: any;
  @Input() printBlank: boolean;
  @Input() isSimplified: boolean;
  @Input() dialogRef: any;
  reviewInspection: boolean;
  @Output() backButton = new EventEmitter();
  @Output() emitErrorMessages = new EventEmitter<any>();
  @ViewChild("formQuestion", { static: false }) testForm: NgForm;
  downstreamType: string;
  downstreamTypes: string[] = ["DCVA", "RPBA", "PVBA", "OTHER"];
  downstreamOthersValue = false;
  downstreamTypeInit: string;
  noFrontImage: boolean;
  noBackImage: boolean;
  fileName: string;
  fileName4: string;
  fileName2: string;
  fileName3: string;
  classSelected: any;
  imageUploadError: string;
  imageUploadError4: string;
  imageUploadError2: string;
  imageUploadError3: string;
  base64logoImage: string;
  image: string;
  image2: string;
  image3: string;
  image4: string;
  inspection: any;
  yesCases: Array<any>;
  assetId: number;
  inspectedDate: any;
  inspectedDateError: string;
  inspectedDateformatted: string;
  replacementValue: boolean;
  additionalRemarks: any;
  additional: string;
  systemStatus: any;
  propertyId: any;
  errorMessage: any[];
  checkbox_authority: any;
  invalidAnswers: any[];
  authRemarks: any;
  attachment: any;
  attachmentOriginal: any;
  inspectionReportID: any;
  pac: any;
  subscriptions: any;
  initialValue: string;
  isPDF: boolean;
  uploadError: any;
  questionsDTO: any[];
  checkBoxQuestions: any;
  imageUploadQuestions: any;
  headingUploadQuestions: any;
  photo1Name: string;
  photo1: string;
  photo1Error: string;
  photo2Name: string;
  photo2Error: string;
  photo3Name: string;
  photo2: string;
  photo3Error: string;
  photo3: string;
  assetTypeid: any;
  sequence: any;
  showHose: boolean;
  showPRV: any;
  class1Questions: Array<string>;
  class2Questions: Array<string>;
  class3Questions: Array<string>;
  addEditProperty: boolean;
  loading: boolean;
  photo4Name: string;
  photo4Error: string;
  photo4: string;
  configureReport: boolean;
  fileNameSimplified: string;
  imageUploadErrorSimplified: string;
  base64logoImageSimplified: any;
  imageSimplified: any;
  userRole: string;
  showSimplified: boolean;
  additionalQuestionExist: boolean;
  isSimplifiedReportEnabled: any;
  isImageUploadEnabled: any;
  imagePlacement: boolean;
  disableSave: boolean;
  submitted: boolean;
  isViewMode = false;
  assetTypeName: string;
  downStreamOthers = "";
  hoodSelected: any;
  othersText: any;

  isDampnerTableQuestion: boolean;

  constructor(
    private inspectionService: InspectionService,
    private route: ActivatedRoute,
    private router: Router,
    public datepipe: DatePipe,
    private authorityService: AuthorityService,
    private loggerService: LoggerService,
    private location: Location,
    private el: ElementRef
  ) {}
  ngOnInit() {

    this.assetTypeid = this.inspectionService.getAssetTypeId()
    this.submitted = false;
    this.uploadError = [];
    this.userRole = localStorage.getItem("userRole");
    this.replacementValue = this.qtd[this.findQuestionId("Install:")]
      ? this.qtd[this.findQuestionId("Install:")] === "Replacement"
        ? true
        : false
      : false;
    this.assetTypeName = this.questions
      ? this.questions[0]
        ? this.questions[0]["assetType"]
          ? this.questions[0]["assetType"]
          : ""
        : ""
      : "";
    // if (this.userRole === 'ROLE_AUTHORITY') {
    //   this.authorityService.getAuthorityPreferences().subscribe((response) => {
    //     if (response && response['authoritySettingDetails']) {
    //       this.isPictureRequired = response['authoritySettingDetails']['isPictureRequired']
    //     }
    //   });
    // } else {
    //   this.isPictureRequired = true;
    // }
    this.loading = true;
    if (window.location.href.indexOf("addEditProperty") > 0) {
      this.addEditProperty = true;
    } else {
      this.addEditProperty = false;
    }
    if (window.location.href.indexOf("configureInspectionReport") > 0) {
      this.configureReport = true;
      this.reviewInspection = true;
      this.assetTypeid = this.inspectionService.getAssetTypeId();
    } else {
      this.configureReport = false;
      this.reviewInspection = false;
    }
    this.class1Questions = [
      "Hydro-tested 150psi or 50 psi greater than pressure for 2 hours (Dry Standpipe Only)",
      'All outlet valves and hose threads checked and have 2 1/2" Cap with 1/8th inch hole?',
      "All outlet valves are fully operational and function properly:",
      "Was 25 psi air test conducted?",
      "Flow test conducted?",
    ];
    this.class2Questions = [
      "Hydro-tested 150 psi or 50 psi greater than head pressure for 2 hours:",
      "Are all hoses, valves and controlling nozzles in good condition?",
      `Have flow test been conducted at highest level for at least
      30 seconds to make sure nozzle will work at pressure available (50 gpm at 35 psi minimum)?`,
      `Have controlling valves been tested to verify that pressure regulating valves operate properly
      (not to exceed 100 psi Tip pressure)?`,
    ];
    this.class3Questions = [
      "Hydro-tested at 150 psi greater than highest Operating pressure?",
      "Was 25 psi air test conducted?",
      'All outlet valves and hose threads checked and have 2 1/2" Cap with 1/8th inch hole?',
      "Flow tests conducted to verify operating pressure of pressure regulating valves (not to exceed 175-psi flowing)?",
    ];
    this.sequence = [];
    this.initialValue = "";
    this.subscriptions = [];
    this.checkBoxQuestions = [];
    this.imageUploadQuestions = [];
    this.headingUploadQuestions = [];
    this.additionalQuestionExist = false;
    if (this.questions) {
      this.questions.forEach((x) => {
        if (
          x &&
          x.questions &&
          x.questions.optionType === "Checkbox" &&
          x.questions.questionType !== "DASC"
        ) {
          this.checkBoxQuestions.push(x.questions.questionId);
        }
        if (x && x.questions && x.questions.isImageUploadEnabled) {
          if (x.questions.mainHeading === "Heading") {
            this.headingUploadQuestions.push(x.questions.questionId);
          } else {
            this.imageUploadQuestions.push(x.questions.questionId);
          }
        }
        if (
          x &&
          x.questions &&
          x.questions.questionType &&
          x.questions.questionType === "Additional"
        ) {
          this.additionalQuestionExist = true;
        }
        if (x.questions.subquestion) {
          x.questions.subquestion.forEach((y) => {
            if (y.isImageUploadEnabled) {
              if (y.mainHeading === "Heading") {
                this.headingUploadQuestions.push(y.questionId);
              } else {
                this.imageUploadQuestions.push(y.questionId);
              }
            }
          });
        }
      });
    }
    if (
      window.location.href.indexOf("reviewInspection") > 0 ||
      window.location.href.indexOf("printReport") > 0 ||
      window.location.href.indexOf("configureInspectionReport") > 0
    ) {
      this.reviewInspection = true;
      if (window.location.href.indexOf("configureInspectionReport") > 0) {
        this.assetTypeid = this.inspectionService.getAssetTypeId();
      } else {
        this.assetTypeid = window.location.href.substring(
          window.location.href.indexOf("id=") + 3,
          window.location.href.length
        );
      }
      if (this.inspectionDataforSecondPage) {
        this.sequence = this.inspectionDataforSecondPage.sequence;
        this.showHose = this.inspectionDataforSecondPage.showHose;
        this.showPRV = this.inspectionDataforSecondPage.showPRV;
      }
    } else {
      this.reviewInspection = null;
    }
    this.invalidAnswers = [];
    this.inspection = {};
    this.inspection.phoneNo = "425-771-1166";
    this.inspection.testingAgency = "ABSCO Alarms Inc.";
    this.inspection.mailingAddress = "	19023 36TH AVE W STE ELYNNWOOD, WA 98036";
    this.yesCases = [1039, 1573];
    this.subscriptions.push(
      this.route.queryParams.subscribe((params) => {
        if (params.viewMode) {
          this.isViewMode = params.viewMode === "false" ? false : true;
          this.reviewInspection = this.isViewMode;
        }
        if (this.router.url.indexOf("fileCabinet") > -1) {
          this.isViewMode = true;
          this.reviewInspection = this.isViewMode;
          if (
            this.inspectionDataforSecondPage &&
            this.inspectionDataforSecondPage.inspectedDate
          ) {
            this.inspectedDate = this.datepipe.transform(
              new Date(this.inspectionDataforSecondPage.inspectedDate),
              "MM-dd-yyyy"
            );
          }
          if (this.inspectionDataforSecondPage) {
            this.additionalRemarks =
              this.inspectionDataforSecondPage.inspectionRemarks;
            this.additional = this.inspectionDataforSecondPage
              .isInspectorCustomArea
              ? "yes"
              : "no";
            this.systemStatus = this.inspectionDataforSecondPage.systemStatus;
          }
          this.checkbox_authority = true;
        }
        if (this.router.url.indexOf("dashboardAuthority") > -1) {
          this.isViewMode = true; 
          this.reviewInspection = this.isViewMode; 
        }
        this.assetId = params.assetId;
        this.propertyId = params.propId;
        this.inspectionReportID = params.editMode;
        this.assetTypeid = params.id;

        if(this.viewReportData && this.viewReportData.id){
          this.assetTypeid = this.viewReportData.id;
        }
                
        this.pac = params.pac;
        this.isImageUploadEnabled = params.isImageUploadEnabled === "true";
        this.isSimplifiedReportEnabled =
          params.isSimplifiedReportEnabled === "true";
        if (this.inspectionReportID && this.inspectionDataforSecondPage) {
          this.showHose = this.inspectionDataforSecondPage.showHose;
          this.showPRV = this.inspectionDataforSecondPage.showPRV;
          this.sequence = this.inspectionDataforSecondPage.sequence;
          this.authRemarks = this.inspectionDataforSecondPage.authorityRemarks;
          if (this.inspectionDataforSecondPage.inspectedDate) {
            (this.inspectedDate = new Date(
              this.inspectionDataforSecondPage.inspectedDate
            )),
              "MM-DD-YYYY";
          }
          this.attachment =
            this.inspectionDataforSecondPage.inspectionAttachment;
          if (
            this.inspectionDataforSecondPage.inspectionAttachment &&
            this.inspectionDataforSecondPage.inspectionAttachment[0] &&
            this.inspectionDataforSecondPage.inspectionAttachment[0]
              .imagePlacement !== "Simple"
          ) {
            this.attachment =
              this.inspectionDataforSecondPage.inspectionAttachment;
          }

          this.additionalRemarks =
            this.inspectionDataforSecondPage.inspectionRemarks;
          this.additional = this.inspectionDataforSecondPage
            .isInspectorCustomArea
            ? "yes"
            : "no";
          this.systemStatus = this.inspectionDataforSecondPage.systemStatus;
        }
      })
    );
    if (!this.attachment || (this.attachment && this.attachment.length === 0)) {
      if (this.inspectionDataforSecondPage) {
        this.attachment = this.inspectionDataforSecondPage.inspectionAttachment;
      }
    }
    if (this.attachment && this.attachment.length > 0) {
      this.attachment.forEach((x) => {
        if (x.imagePlacement === "Simple") {
          this.imagePlacement = true;
          this.imageSimplified = x.attachment;
          this.fileNameSimplified = x.imageName;
        }
        if (x.imagePlacement === "Main") {
          this.image = x.attachment;
          this.fileName = x.imageName;
        }
        if (x.imagePlacement !== "Main" && this.assetTypeid === "14") {
          if (x.imagePlacement === "Second") {
            this.fileName4 = x.imageName;
            this.image4 = x.attachment;
          } else if (x.imagePlacement === "Third") {
            this.fileName2 = x.imageName;
            this.image2 = x.attachment;
          } else if (x.imagePlacement === "Fourth") {
            this.fileName3 = x.imageName;
            this.image3 = x.attachment;
          }
        }
        if (
          x.imagePlacement !== "Main" &&
          x.imagePlacement !== "Simple" &&
          this.assetTypeid !== "14"
        ) {
          if (!this.photo1) {
            this.photo1 = x.attachment;
            this.photo1Name = x.imageName;
            return;
          }
          if (!this.photo2) {
            this.photo2 = x.attachment;
            this.photo2Name = x.imageName;
            return;
          }
          if (!this.photo3) {
            this.photo3 = x.attachment;
            this.photo3Name = x.imageName;
            return;
          }
          if (!this.photo4) {
            this.photo4 = x.attachment;
            this.photo4Name = x.imageName;
            return;
          }
        }
      });
    }
    if (this.inspectionDataforSecondPage) {
      this.showSimplified =
        this.inspectionDataforSecondPage.inspectionType === "SIMPLIFIED_REPORT"
          ? true
          : false;
      if (this.inspectionDataforSecondPage.inspectionAttachment) {
        if (
          this.inspectionDataforSecondPage.inspectionAttachment.find(
            (x) => x.imagePlacement !== "Simple"
          )
        ) {
          this.attachmentOriginal =
            this.inspectionDataforSecondPage.inspectionAttachment.find(
              (x) => x.imagePlacement !== "Simple"
            );
        }
      }
    }
    this.loading = false;

    this.configureFireAndSmokeDamperAsset()

  }

  isValidCommertialDate(dateString) {
    // First check for the pattern
    if (!/^\d{1,2}-\d{4}$/.test(dateString)) {
      return false;
    }

    // Parse the date parts to integers
    const parts = dateString.split("-");
    const year = parseInt(parts[1], 10);
    const month = parseInt(parts[0], 10);

    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month === 0 || month > 12) {
      return false;
    } else {
      return true;
    }
  }
  // Validates that the input string is a valid date formatted as "mm/dd/yyyy"
  isValidDate(dateString) {
    // First check for the pattern
    if (!/^\d{1,2}-\d{1,2}-\d{4}$/.test(dateString)) {
      return false;
    }

    // Parse the date parts to integers
    const parts = dateString.split("-");
    const day = parseInt(parts[1], 10);
    const month = parseInt(parts[0], 10);
    const year = parseInt(parts[2], 10);

    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month === 0 || month > 12) {
      return false;
    }

    const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 == 0 || (year % 100 !== 0 && year % 4 === 0)) {
      monthLength[1] = 29;
    }

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
  }

  formatDate(date) {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return [month, day, year].join("-");
  }

  checkErrorIninspectedDate(fromDate) {
    // if(null !== fromDate){
    this.inspectedDateformatted = moment(fromDate)
      .format("MM-DD-YYYY")
      .toString();
    this.inspectedDateError = "";
    if (this.inspectedDateformatted.length === 0) {
      return this.inspectedDateError;
    }
    if (this.inspectedDateformatted.length !== 10) {
      this.inspectedDateError = "* Enter valid Inspected Date";
    }
    if (!this.validateDate(this.inspectedDateformatted)) {
      this.inspectedDateError = "Invalid Date Format";
    }
    const date = this.inspectedDateformatted.split("-");
    if (
      date.length !== 3 ||
      date[0].length !== 2 ||
      date[1].length !== 2 ||
      date[2].length !== 4
    ) {
      this.inspectedDateError = " Enter valid Inspected Date";
    } else if (!moment(this.inspectedDateformatted, "MM-DD-YYYY").isValid()) {
      this.inspectedDateError = " Enter valid Inspected Date";
    } else if (
      moment(this.inspectedDateformatted).isAfter(moment(new Date()))
    ) {
      this.inspectedDateError = " Future Inspected Date is not allowed";
    }
    return this.inspectedDateError;
    // }
  }

  validateDate(inspectionDueDate: any) {
    let dateformat =
      /^(0?[1-9]|1[0-2])[\-](0?[1-9]|[1-2][0-9]|3[01])[\-]\d{4}$/;

    // Match the date format through regular expression
    if (inspectionDueDate.match(dateformat)) {
      let operator = inspectionDueDate.split("-");

      // Extract the string into month, date and year
      let datepart = [];
      if (operator.length > 1) {
        datepart = inspectionDueDate.split("-");
      }
      let month = parseInt(datepart[0]);
      let day = parseInt(datepart[1]);
      let year = parseInt(datepart[2]);

      // Create list of days of a month
      let ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      if (year < 1000 || year > 3000 || month === 0 || month > 12) {
        return "";
      }
      if (month == 1 || month > 2) {
        if (day > ListofDays[month - 1]) {
          ///This check is for Confirming that the date is not out of its range
          return "";
        }
      } else if (month == 2) {
        let leapYear = false;
        if ((!(year % 4) && year % 100) || !(year % 400)) {
          leapYear = true;
        }
        if (leapYear == false && day >= 29) {
          return "";
        } else if (leapYear == true && day > 29) {
          return "";
        }
      }
    } else {
      return "";
    }
    return inspectionDueDate;
  }
  downloadImage(image) {
    const fileURL = image,
      fileName = image.split("/").pop();
    // for non-IE
    if (!(window as any).ActiveXObject) {
      var save = document.createElement("a");
      save.href = fileURL;
      save.target = "_blank";
      save.download = fileName || "unknown";

      var evt = new MouseEvent("click", {
        view: window as any,
        bubbles: true,
        cancelable: false,
      });
      save.dispatchEvent(evt);

      ((window as any).URL || (window as any).webkitURL).revokeObjectURL(
        save.href
      );
    }

    // for IE < 11
    else if (!!(window as any).ActiveXObject && document.execCommand) {
      var _window = (window as any).open(fileURL, "_blank");
      _window.document.close();
      _window.document.execCommand("SaveAs", true, fileName || fileURL);
      _window.close();
    }
  }

  checkvalueforSerNum(data, data2) {
    if (data2.question === "Old Ser#") {
      if (this.replacementValue) {
        return true;
      }
      return false;
    }
    return true;
  }
  installCheckBox($event: MatRadioChange) {
    if ($event && $event.value === "Replacement") {
      this.replacementValue = true;
    } else {
      this.replacementValue = false;
    }
  }
  changeofDownStream(event) {
    if (event.value === "DCVA") {
      this.qtd[this.findQuestionId("Downstream Process:")] = "DCVA";
      this.downstreamOthersValue = false;
      this.qtd[this.findQuestionId("Others")] = " ";
    } else if (event.value === "RPBA") {
      this.qtd[this.findQuestionId("Downstream Process:")] = "RPBA";
      this.downstreamOthersValue = false;
      this.qtd[this.findQuestionId("Others")] = " ";
    } else if (event.value === "PVBA") {
      this.qtd[this.findQuestionId("Downstream Process:")] = "PVBA";
      this.downstreamOthersValue = false;
      this.qtd[this.findQuestionId("Others")] = " ";
    } else if (event.value === "OTHER") {
      this.qtd[this.findQuestionId("Downstream Process:")] = "OTHERS";
      this.downstreamOthersValue = true;
    }
  }
  otherValues(event) {
    this.downStreamOthers = event.value;
  }

  getErrorMessage(): string {
    return "";
  }

  manageFireAndSmokeDamperQuestions() : boolean{
    let err = Object.keys(this.qtd).filter(x => x.indexOf("firesmokedamper") > 0 && !this.qtd[x] && x.split("~")[0] != this.findQuestionId("Comments:"))

    let binderQuestionId  = this.findQuestionId("Binder left on-site?")

    if(err.length > 0 ||  !this.qtd[binderQuestionId])
    return false;
    

    Object.keys(this.qtd).forEach((key) => {
      if (key.indexOf("firesmokedamper") > 0) {
        let _questionId = key.split("~")[0];
        let _index = key.split("~")[2];
        let _answer = this.qtd[key];

        if (this.qtd[_questionId]) {
          this.qtd[_questionId][_index] = _answer ? _answer : null;
        } else {
          let arr = [];
          arr[_index] = _answer ? _answer : null;
          this.qtd[_questionId] = arr;
        }

        this.qtd[_questionId] = Array.from(this.qtd[_questionId], item => item || '')
        delete this.qtd[key]
      }
    });

    Object.keys(this.qtd).forEach((key) => {
      if(this.qtd[key] instanceof Array){
        this.qtd[key] = this.qtd[key].join('~')
      }
    });

    return true;
  }

  onSubmit() {
    this.errorMessage = [];
    this.submitted = true;
    if(this.assetTypeid === '9'){
      if(this.photo1Error){
        this.testForm.controls['name-photo1'].setErrors({ 'invalidPhoto1': true });
      }
      if(this.photo2Error){
        this.testForm.controls['name-photo2'].setErrors({ 'invalidPhoto2': true });
      }
      if(this.photo3Error){
        this.testForm.controls['name-photo3'].setErrors({ 'invalidPhoto3': true });
      }
      if(this.photo1Error || this.photo2Error || this.photo3Error){
        alert('Please upload valid pictures to proceed further.');
        return;
      }
    }

    let error = false;

    if (
      this.assetTypeid === "17" &&
      this.inspectionResult &&
      this.inspectionResult.questionModelVersion &&
      this.inspectionResult.questionModelVersion > 4
    ) {
      let response = this.manageFireAndSmokeDamperQuestions();
      if(!response){
        this.inspectionService.setErrorInFireAndDamper(true);
        this.errorMessage.push("* Please enter missing damper identifications")
        error = true;        
      }else{
        this.inspectionService.setErrorInFireAndDamper(false);
      }
    }

    if (
      this.getDownstreamValue() === "OTHER" &&
      this.qtd[this.findQuestionId("Others")] &&
      this.qtd[this.findQuestionId("Others")] === " "
    ) {
      if (
        this.errorMessage.indexOf(
          "* Please enter a valid value for Downstream"
        ) < 0
      ) {
        this.errorMessage.push("* Please enter a valid value for Downstream");
        document.getElementById("anserText_1223").focus();
      }
    } else {
      this.errorMessage.splice(
        this.errorMessage.indexOf(
          "* Please enter a valid value for Downstream"
        ),
        1
      );
    }
    if (!this.checkbox_authority) {
      if (
        this.errorMessage.indexOf("* Please certify the above inspection") < 0
      ) {
        this.errorMessage.push("* Please certify the above inspection");
      }
    } else {
      if (
        this.errorMessage.indexOf("* Please certify the above inspection") >= 0
      ) {
        this.errorMessage.splice(
          this.errorMessage.indexOf("* Please certify the above inspection"),
          1
        );
      }
    }
    if (!this.systemStatus) {
      if (this.errorMessage.indexOf("* Please enter the system status") < 0) {
        this.errorMessage.push("* Please enter the system status");
      }
    } else {
      if (this.errorMessage.indexOf("* Please enter the system status") >= 0) {
        this.errorMessage.splice(
          this.errorMessage.indexOf("* Please enter the system status"),
          1
        );
      }
    }
    if (!this.testForm.valid) {
      if (this.errorMessage.indexOf("* Please answer all the questions") < 0) {
        this.errorMessage.push("* Please answer all the questions");
      }
    } else {
      if (this.errorMessage.indexOf("* Please answer all the questions") >= 0) {
        this.errorMessage.splice(
          this.errorMessage.indexOf("* Please answer all the questions"),
          1
        );
      }
    }
    if (this.invalidAnswers.length > 0) {
      if (this.invalidAnswers.find((x) => x.type === "date")) {
        if (this.errorMessage.indexOf("* Please enter a valid date") < 0) {
          this.errorMessage.push("* Please enter a valid date");
        }
      } else {
        if (this.errorMessage.indexOf("* Please enter a valid date") >= 0) {
          this.errorMessage.splice(
            this.errorMessage.indexOf("* Please enter a valid date"),
            1
          );
        }
      }

      if (this.invalidAnswers.find((x) => x.type === "Futuredate")) {
        if (
          this.errorMessage.indexOf("Future Inspected Date is not allowed") < 0
        ) {
          this.errorMessage.push("Future Inspected Date is not allowed");
        }
      } else {
        if (
          this.errorMessage.indexOf("Future Inspected Date is not allowed") >= 0
        ) {
          this.errorMessage.splice(
            this.errorMessage.indexOf("Future Inspected Date is not allowed"),
            1
          );
        }
      }

      if (this.invalidAnswers.find((x) => x.type === "text")) {
        if (this.errorMessage.indexOf("* Please enter a valid value") < 0) {
          this.errorMessage.push("* Please enter a valid value");
        }
      } else {
        if (this.errorMessage.indexOf("* Please enter a valid value") >= 0) {
          this.errorMessage.splice(
            this.errorMessage.indexOf("* Please enter a valid value"),
            1
          );
        }
      }
    }

    if (this.inspectedDateError.length > 0) {
      this.errorMessage.push(this.inspectedDateError);
      const invalidElement =
        this.el.nativeElement.querySelector("#inspectedDateId");
      if (invalidElement) {
        invalidElement.focus();
        invalidElement.scrollIntoView();
      }
    } else if (this.errorMessage.indexOf(this.inspectedDateError) > -1) {
      this.errorMessage.splice(
        this.errorMessage.indexOf(this.inspectedDateError),
        1
      );
    }

    const a = this.questions.filter(
      (elem) => elem.questions.question === "FIRE DEPARTMENT CONNECTION"
    );
    if (a.length > 0) {
      this.removeSubQuestions(a);
    }

    let questionsDTO = [];
    const tableObjects = [];
    if (this.assetTypeid === "15") {
      Object.keys(this.qtd).forEach((key) => {
        if (key.indexOf("~") > 0) {
          delete this.qtd[key];
        }
      });
    }
    // Checkbox type question
    Object.keys(this.qtd).map((key) => {
      if (key.indexOf("*o*") !== -1) {
        const originalKey = key.split("*")[0];
        if (this.qtd.hasOwnProperty(originalKey)) {
          delete this.qtd[originalKey];
        }
      }
    });

    Object.keys(this.qtd).forEach((key) => {
      /* Table Type Questions */
      if (
        key.indexOf("checkbox") < 0 &&
        key.indexOf("~") > 0 &&
        // clean agent & fire alarm table questions not required.
        this.assetTypeid !== "10" &&
        this.assetTypeid !== "3"
      ) {
        const answer = { index: "", max: "", value: "" };
        answer.index = key.split("~")[2];
        if (key.indexOf("standpipe") > 0) {
          answer.max = this.inspectionService.getStandPipeRows() || 1;
        } else if (key.indexOf("hose") > 0) {
          answer.max = this.inspectionService.getHoseCount() || 1;
        } else {
          answer.max = key.split("~")[3];
        }
        // answer.max = key.indexOf('standpipe') > 0 ? (this.inspectionService.getStandPipeRows() || 1) :
        //   key.indexOf('hose') > 0 ? this.inspectionService.getHoseCount() : key.split('~')[3];
        answer.value = this.qtd[key];
        const questionId = key.split("~")[0];
        if (
          tableObjects.filter((x) => x.question === questionId).length === 0
        ) {
          tableObjects.push({ question: questionId, answer: [answer] });
        } else {
          tableObjects
            .filter((x) => x.question === questionId)[0]
            .answer.push(answer);
        }
      }
      if (
        key.indexOf("checkbox") < 0 &&
        key.indexOf("o") < 0 &&
        key.indexOf("~") < 0
      ) {
        const value = this.qtd[key];
        let updatedValue;
        if (value && typeof value === "object" && value.length > 0) {
          updatedValue = value[0];
        } else {
          updatedValue = value;
        }
        questionsDTO.push({
          answer: updatedValue,
          questionId: key,
        });
      }
      if (
        key.indexOf("o") > 0 &&
        key.indexOf("checkbox") < 0 &&
        key.indexOf("hose") < 0
      ) {
        if (this.qtd[key]) {
          const value = key.split("*")[2];
          const newKey = key.split("*")[0];
          questionsDTO.push({
            answer: value,
            questionId: newKey,
          });
        }
      }
      if (key.indexOf("checkbox") >= 0) {
        const value = this.qtd[key];
        let newKey;
        if (value) {
          newKey = key.substring(0, key.length - 8);
          this.qtd[newKey] = "N/A";
          if (questionsDTO.find((x) => x.questionId === newKey)) {
            questionsDTO.find((x) => x.questionId === newKey).answer = "N/A";
          } else {
            questionsDTO.push({
              answer: "N/A",
              questionId: newKey,
            });
          }
        }
      }
    });

    if (tableObjects.length > 0) {
      tableObjects.forEach((x) => {
        let finalAnswer = "";
        for (let i = 0; i < x.answer[0].max; i++) {
          if (x.answer.find((y) => +y.index === i)) {
            finalAnswer =
              finalAnswer + "~" + x.answer.find((y) => +y.index === i).value;
          } else {
            finalAnswer = finalAnswer + "~" + " ";
          }
        }
        if (questionsDTO.find((y) => y.questionId === x.question)) {
          questionsDTO.find((y) => y.questionId === x.question).answer =
            finalAnswer.substring(1);
        } else {
          questionsDTO.push({
            answer: finalAnswer.substring(1),
            questionId: x.question,
          });
        }
      });
    }

    Object.keys(this.images).forEach((key) => {
      const answerImages = [];
      const images = this.images[key];
      images.forEach((x) => {
        answerImages.push({ imageContent: x, imageName: " " });
      });

      if (this.imageUploadQuestions.indexOf(+key) >= 0) {
        if (questionsDTO.find((x) => x.questionId === key)) {
          questionsDTO.find((x) => x.questionId === key).answerImages = images;
        } else {
          if (Number(this.assetTypeid) === 11) {
            if (questionsDTO.length > 0) {
              questionsDTO.push({ questionId: key, answerImages: images });
            } else {
              questionsDTO = [{ questionId: key, answerImages: images }];
            }
          }
        }
      } else {
        if (questionsDTO.length > 0) {
          questionsDTO.push({ questionId: key, answerImages: images });
        } else {
          questionsDTO = [{ questionId: key, answerImages: images }];
        }
      }
    });

    questionsDTO.map((elem) => {
      if (
        elem.answer !== null &&
        elem.answer &&
        (elem.answer.hasOwnProperty("unitsInBuilding") ||
          elem.answer.hasOwnProperty("unitsTested") ||
          elem.answer.hasOwnProperty("resultsAcceptable"))
      ) {
        elem.tableAnswers = elem.answer;
        delete elem.answer;
      }
    });

    let notAllQuestionsHaveBeenAnswered = false;
    this.questionsDTO = questionsDTO;
    this.checkBoxQuestions.forEach((x) => {
      if (!this.questionsDTO.find((y) => +y.questionId === x)) {
        notAllQuestionsHaveBeenAnswered = true;
      }
    });
    if (
      this.inspectionDataforSecondPage.inspectionType !== "SIMPLIFIED_REPORT" &&
      notAllQuestionsHaveBeenAnswered
    ) {
      if (this.errorMessage.indexOf("* Please answer all the questions") < 0) {
        this.errorMessage.push("* Please answer all the questions");
      }
    } else {
      if (this.testForm.valid) {
        if (
          this.errorMessage.indexOf("* Please answer all the questions") >= 0
        ) {
          this.errorMessage.splice(
            this.errorMessage.indexOf("* Please answer all the questions"),
            1
          );
        }
      }
      if (
        this.inspectionDataforSecondPage.inspectionType === "SIMPLIFIED_REPORT"
      ) {
        if (
          !this.imageSimplified ||
          (this.imageSimplified && this.imageSimplified.trim() === "")
        ) {
          if (this.errorMessage.indexOf("* Please upload a document") < 0) {
            this.errorMessage.push("* Please upload a document");
          }
        } else {
          if (this.errorMessage.indexOf("* Please upload a document") >= 0) {
            this.errorMessage.splice(
              this.errorMessage.indexOf("* Please upload a document"),
              1
            );
          }
        }
      }      

      if (this.errorMessage.length === 0 && !error) {
        this.inspectionResult.inspectionAttachment = [];
        this.emitErrorMessages.emit([]);
        if (
          confirm(
            "Inspection report will be saved in the system. Do you want to save it?"
          )
        ) {
          this.inspectionResult.authorityRemarks = "";
          /* TODO on POST */
          this.inspectionResult.id = null;
          this.inspectionResult.inspectedDate =
            this.inspectedDateformatted.split("-")[2] +
            "-" +
            this.inspectedDateformatted.split("-")[0] +
            "-" +
            this.inspectedDateformatted.split("-")[1] +
            "T00:00:00.000Z";
          //  if (this.inspectionDataforSecondPage.inspectionType === 'SIMPLIFIED_REPORT') {
          if (this.fileNameSimplified) {
            this.inspectionResult.inspectionAttachment.push({
              attachment: this.imageSimplified,
              imageName: this.fileNameSimplified,
              imagePlacement: "Simple",
            });
          }
          // }
          if (this.fileName !== "" && this.fileName != undefined) {
            if (this.image) {
              this.inspectionResult.inspectionAttachment.push({
                attachment: this.image,
                imageName: this.fileName,
                imagePlacement: "Main",
              });
            }
          }
          if (this.fileName4 !== "" && this.fileName4 != undefined) {
            if (this.image4) {
              this.inspectionResult.inspectionAttachment.push({
                attachment: this.image4,
                imageName: this.fileName4,
                imagePlacement: "Second",
              });
            }
          }
          if (this.fileName2 !== "" && this.fileName2 != undefined) {
            if (this.image2) {
              this.inspectionResult.inspectionAttachment.push({
                attachment: this.image2,
                imageName: this.fileName2,
                imagePlacement: "Third",
              });
            }
          }
          if (this.fileName3 !== "" && this.fileName3 != undefined) {
            if (this.image3) {
              this.inspectionResult.inspectionAttachment.push({
                attachment: this.image3,
                imageName: this.fileName3,
                imagePlacement: "Fourth",
              });
            }
          }

          if (this.photo1) {
            this.inspectionResult.inspectionAttachment.push({
              attachment: this.photo1,
              imageName: this.photo1Name,
            });
          }
          if (this.photo2) {
            this.inspectionResult.inspectionAttachment.push({
              attachment: this.photo2,
              imageName: this.photo2Name,
            });
          }
          if (this.photo3) {
            this.inspectionResult.inspectionAttachment.push({
              attachment: this.photo3,
              imageName: this.photo3Name,
            });
          }

          if (this.photo4) {
            this.inspectionResult.inspectionAttachment.push({
              attachment: this.photo4,
              imageName: this.photo4Name,
            });
          }

          this.inspectionResult.inspectionRemarks = this.additionalRemarks;
          this.inspectionResult.isInspectorCustomArea =
            this.additional === "yes" ? true : false;
          this.inspectionResult.systemStatus = this.systemStatus;

          if( this.assetTypeid === '9' && this.inspectionResult.inspectionAttachment.length<3 ){
             alert("Please upload mandatory pictures to proceed further.");
             return;
          }
          
          if (this.inspectionReportID === "false") {
            this.disableSave = true;

            let apiPayload = {
              inspectionResultDto : this.inspectionResult, 
              answerDtoList: this.questionsDTO
            }

            this.loggerService.pushToServer({
              Line: "questions.component.ts:1159",
              API: `/inspection/answer/${this.assetId}`,
              Data: {apiPayload},
            });

            this.subscriptions.push(
              this.inspectionService.sendConsolidatedInspectionResultAndAnswer(this.assetId, apiPayload).subscribe((result) => {
                if(result){
                  this.disableSave = false;
                  // alert(result);
                } else if (result === false){
                  alert("Error occurred while saving inspection report in the system. Please re-fill and save the report. If same issue occurs again, please contact support@tegrisfire.com.")
                }
                this.redirectDashboard();
              }, (error) => {
                if(error){
                  alert(error.error.message)
                }
              })

            );



          
            // this.subscriptions.push(this.inspectionService.postInspectionResult(this.inspectionResult, this.assetId).subscribe((result) => {
            //   if (result) {
            //     this.disableSave = false;
            //     this.subscriptions.push(this.inspectionService.postAnswer(result.id, this.questionsDTO).subscribe((res) => {
            //       if (res) {
            //         alert(res);
            //       }
            //       this.redirectDashboard();
            //     }, (error) => {
            //       if (error) {
            //         alert(error.error.message);
            //       }
            //     }));
            //   }
            // }, (error) => {
            //   if (error) {
            //     alert(error.error.message);
            //   }
            // }));
          } else {
            this.disableSave = true;
            this.inspectionResult.id = this.inspectionDataforSecondPage.id;

            let apiPayload = {
              inspectionResultDto : this.inspectionResult, 
              answerDtoList: this.questionsDTO
            }

            this.loggerService.pushToServer({
              Line: "questions.component.ts:1207",
              API: `/inspection/answer/${this.assetId}`,
              Data: apiPayload,
            });

            this.subscriptions.push(
              this.inspectionService.sendConsolidatedInspectionResultAndAnswer(this.assetId, apiPayload).subscribe((result) => {
                if(result){
                  this.disableSave = false;
                  // alert(result);
                }
                else if (result === false){
                  alert("Error occurred while saving inspection report in the system. Please re-fill and save the report. If same issue occurs again, please contact support@tegrisfire.com.")
                }
                this.redirectDashboard();
              }, (error) => {
                if(error){
                  alert(error.error.message)
                }
              })

            );


            // this.subscriptions.push(this.inspectionService.putInspectionResult(this.inspectionResult, this.assetId).subscribe((result) => {
            //   if (result) {
            //     this.disableSave = false;
            //     this.subscriptions.push(this.inspectionService.postAnswer(result.id, this.questionsDTO).subscribe((res) => {
            //       if (res) {
            //         alert(res);
            //       }
            //       this.redirectDashboard();
            //     }, (error) => {
            //       if (error) {
            //         alert(error.error.message);
            //       }
            //     }));
            //   }
            // }, (error) => {
            //   if (error) {
            //     alert(error.error.message);
            //   }
            // }));
          }
        }
      } else {
        this.emitErrorMessages.emit(this.errorMessage);
        alert("* Please answer all the questions.");
      }
    }
  }


  subQuestionsCondition(question) {
    if (
      this.inspectionDataforSecondPage &&
      this.inspectionDataforSecondPage.accessSpecifier === "CUSTOM"
    ) {
      return false;
    }
    return (
      (question.questions.subquestion &&
        question.questions.subquestion &&
        question.questions.subquestion.questionType &&
        question.questions.subquestion.questionType !== "Custom") ||
      (question.questions.hasDependents &&
        !question.questions.dependencyValue &&
        this.qtd[question.questions.questionId] === "Yes") ||
      (question.questions.question === "CLASS I" &&
        this.qtd[this.findQuestionId("Standpipe Class:")] === "class1") ||
      (question.questions.question === "CLASS II" &&
        this.qtd[this.findQuestionId("Standpipe Class:")] === "class2") ||
      (question.questions.question === "CLASS III" &&
        this.qtd[this.findQuestionId("Standpipe Class:")] === "class3") ||
      this.printBlank
    );
  }

  removeSubQuestions(question) {
    if (this.qtd[question[0].questions.questionId] === "No") {
      question[0].questions.subquestion.map((subQ) => {
        this.qtd[subQ.questionId] = null;
        if (this.images[subQ.questionId]) {
          delete this.images[subQ.questionId];
        }
      });
    }
  }

  removeImage(questionId, index, uploadTwice?: string) {
    if (this.images[questionId] && this.images[questionId].length > 0) {
      this.images[questionId].splice(index, 1);
    }
    if (
      !uploadTwice &&
      this.images[questionId] &&
      this.images[questionId].length < 3
    ) {
      if (
        this.uploadError[questionId] &&
        this.uploadError[questionId].indexOf(
          "Cannot upload more than 3 images"
        ) >= 0
      ) {
        this.uploadError[questionId].splice(
          this.uploadError[questionId].indexOf(
            "Cannot upload more than 3 images"
          ),
          1
        );
      }
    }
    if (
      uploadTwice &&
      this.images[questionId] &&
      this.images[questionId].filter((x) => x.imagePlacement === uploadTwice)
        .length < 3
    ) {
      if (
        this.uploadError[questionId] &&
        this.uploadError[questionId][uploadTwice] &&
        this.uploadError[questionId][uploadTwice].indexOf(
          "Cannot upload more than 3 images"
        ) >= 0
      ) {
        this.uploadError[questionId][uploadTwice].splice(
          this.uploadError[questionId][uploadTwice].indexOf(
            "Cannot upload more than 3 images"
          ),
          1
        );
      }
    }
    if (this.images[questionId].length === 0) {
      this.uploadError[questionId] = [];
      this.uploadError[questionId].length = 0;
      if (this.uploadError[questionId][uploadTwice]) {
        this.uploadError[questionId][uploadTwice].length = 0;
      }
    }

    if (
      this.images[questionId] &&
      this.images[questionId].filter((x) => x.imagePlacement === uploadTwice)
        .length === 0
    ) {
      if (uploadTwice === "Front") {
        this.inspectionDataforSecondPage.noFrontImage = true;
      } else {
        this.inspectionDataforSecondPage.noBackImage = true;
      }
    }
  }
  uploadFile(
    questonid,
    event,
    files: FileList,
    uploadTwice?: string,
    countOfImages?: number
  ) {
    if (!this.images) {
      this.images = {};
    }
    if (!this.images[questonid]) {
      this.images[questonid] = [];
    }
    if (this.images[questonid].length > 0 && countOfImages === 1) {
      alert(
        "One image is already uploaded. Please delete the other image to upload new image."
      );
      return;
    }
    if (
      uploadTwice &&
      this.images[questonid] &&
      !this.images[questonid][uploadTwice]
    ) {
      this.images[questonid][uploadTwice] = [];
    }
    if (!this.uploadError[questonid]) {
      this.uploadError[questonid] = [];
    }
    if (
      uploadTwice &&
      this.uploadError[questonid] &&
      !this.uploadError[questonid][uploadTwice]
    ) {
      this.uploadError[questonid][uploadTwice] = [];
    }
    const image = files.item(0);
    const fileName = image.name;
    // this.fileName = image.name;
    // this.initialValue = this.fileName;
    if (image.size < 1097152) {
      if (
        image.type.toLowerCase() === "image/gif" ||
        image.type.toString() === "image/jpeg"
      ) {
        this.uploadError[questonid].length = 0;
        const reader = new FileReader();
        reader.readAsDataURL(image);

        reader.onload = () => {
          const base64logoImage = reader.result.toString();
          // tslint:disable-next-line: no-shadowed-variable
          const image = reader.result.toString();
          if (!uploadTwice) {
            if (this.images[questonid].length < 3) {
              this.images[questonid].push({
                imageContent: image,
                imageName: fileName,
              });
            } else {
              this.uploadError[questonid].push(
                "Cannot upload more than 3 images"
              );
            }
          } else {
            if (
              this.images[questonid] &&
              this.images[questonid].filter(
                (x) => x.imagePlacement === uploadTwice
              ).length < 3
            ) {
              this.images[questonid].push({
                imageContent: image,
                imageName: fileName,
                imagePlacement: uploadTwice,
              });
            } else {
              this.uploadError[questonid][uploadTwice].push(
                "Cannot upload more than 3 images"
              );
            }
          }
          event.value = "";
        };

        reader.onerror = (error) => {
          this.loggerService.log("Error on Loading Image", error);
          if (!uploadTwice) {
            this.uploadError[questonid].push("Error while Loading File");
          } else {
            this.uploadError[questonid][uploadTwice].push(
              "Error while Loading File"
            );
          }
          // this.imageUploadError = "Error while Loading File"
        };
      } else {
        if (!uploadTwice) {
          this.uploadError[questonid].push("Invalid File Type");
        } else {
          this.uploadError[questonid][uploadTwice].push("Invalid File Type");
        }
        // this.imageUploadError = "Invalid File Type"
      }
    } else if (image.size > 1097152) {
      if (!uploadTwice) {
        this.uploadError[questonid].push("File Size is greater than 1MB");
      } else {
        this.uploadError[questonid][uploadTwice].push(
          "File Size is greater than 1MB"
        );
      }
      // this.imageUploadError = ""
    } else if (
      image.type.toLowerCase() !== "gif" ||
      image.type.toString() !== "jpg"
    ) {
      if (!uploadTwice) {
        this.uploadError[questonid].push("Invalid File Type");
      } else {
        this.uploadError[questonid][uploadTwice].push("Invalid File Type");
      }
    }
  }
  removeFile() {
    this.fileName = "";
  }

  removeFileEmergencyRes(event) {
    if (event.id === "removeImg4") {
      this.fileName4 = "";
    } else if (event.id === "removeImg2") {
      this.fileName2 = "";
    } else if (event.id === "removeImg3") {
      this.fileName3 = "";
    }
  }

  uploadFileMain(event, files: FileList) {
    const image = files.item(0);
    this.fileName = image.name;
    if (
      (image.size < 1097152 && this.assetTypeid !== "14") ||
      (image.size <= 5242880 && this.assetTypeid === "14")
    ) {
      this.imageUploadError = "";
      if (
        image.type.toLowerCase() === "image/gif" ||
        image.type.toString() === "image/jpeg" ||
        image.type.toString() === "application/pdf"
      ) {
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = () => {
          this.base64logoImage = reader.result.toString();
          this.image = reader.result.toString();
          event.value = "";
        };

        reader.onerror = (error) => {
          this.loggerService.log("Error on Loading Image", error);
          this.imageUploadError = "Error while Loading File";
        };
      } else {
        this.imageUploadError = "Invalid File Type";
      }
    } else if (image.size > 1097152 && this.assetTypeid !== "14") {
      this.imageUploadError = "File Size is greater than 1MB";
    } else if (image.size > 5242880 && this.assetTypeid === "14") {
      this.imageUploadError = "File Size is greater than 5MB";
    } else if (
      image.type.toLowerCase() !== "gif" ||
      image.type.toString() !== "jpg" ||
      image.type.toString() !== "application/pdf"
    ) {
      this.imageUploadError = "Invalid File Type";
    }
  }

  uploadFileMainEmergencyResp(event, files: FileList) {
    const image = files.item(0);
    var filenum = event.id;
    if (event.id === "img4") {
      this.fileName4 = image.name;
    } else if (event.id === "img2") {
      this.fileName2 = image.name;
    } else if (event.id === "img3") {
      this.fileName3 = image.name;
    }
    if (image.size <= 5242880) {
      if (
        image.type.toLowerCase() === "image/gif" ||
        image.type.toString() === "image/jpeg" ||
        image.type.toString() === "application/pdf"
      ) {
        if (event.id === "img4") {
          this.imageUploadError4 = "";
        } else if (event.id === "img2") {
          this.imageUploadError2 = "";
        } else if (event.id === "img3") {
          this.imageUploadError3 = "";
        }
        const reader = new FileReader();
        reader.readAsDataURL(image);

        reader.onload = () => {
          this.base64logoImage = reader.result.toString();
          if (event.id === "img2") {
            this.image2 = reader.result.toString();
          }
          if (event.id === "img3") {
            this.image3 = reader.result.toString();
          }
          if (event.id === "img4") {
            this.image4 = reader.result.toString();
          }
          event.value = "";
        };

        reader.onerror = (error) => {
          this.loggerService.log("Error on Loading Image", error);
          this.imageUploadError = "Error while Loading File";
        };
      } else {
        if (event.id === "img2") {
          this.imageUploadError2 = "Invalid File Type";
        } else if (event.id === "img3") {
          this.imageUploadError3 = "Invalid File Type";
        } else if (event.id === "img4") {
          this.imageUploadError4 = "Invalid File Type";
        }
      }
    } else if (image.size > 5242880) {
      if (event.id === "img2") {
        this.imageUploadError2 = "File Size is greater than 5MB";
      } else if (event.id === "img3") {
        this.imageUploadError3 = "File Size is greater than 5MB";
      } else if (event.id === "img4") {
        this.imageUploadError4 = "File Size is greater than 5MB";
      }
    } else if (
      image.type.toLowerCase() !== "gif" ||
      image.type.toString() !== "jpg" ||
      image.type.toString() !== "application/pdf"
    ) {
      if (event.id === "img2") {
        this.imageUploadError2 = "Invalid File Type";
      } else if (event.id === "img3") {
        this.imageUploadError3 = "Invalid File Type";
      } else if (event.id === "img4") {
        this.imageUploadError4 = "Invalid File Type";
      }
    }
  }

  uploadPhoto1(event, files: FileList) {
    const image = files.item(0);
    this.photo1Name = image.name;
    if (image.size < 1097152) {
      if (
        image.type.toLowerCase() === "image/gif" ||
        image.type.toString() === "image/jpeg"
      ) {
        this.photo1Error = "";
        const reader = new FileReader();
        reader.readAsDataURL(image);

        reader.onload = () => {
          this.photo1 = reader.result.toString();
          event.value = "";
        };

        reader.onerror = (error) => {
          this.loggerService.log("Error on Loading Image", error);
          this.photo1Error = "Error while Loading File";
        };
      } else {
        this.photo1Error = "Invalid File Type";
      }
    } else if (image.size > 1097152) {
      this.photo1Error = "File Size is greater than 1MB";
    } else if (
      image.type.toLowerCase() !== "gif" ||
      image.type.toString() !== "jpg"
    ) {
      this.photo1Error = "Invalid File Type";
    }
  }

  uploadPhoto2(event, files: FileList) {
    const image = files.item(0);
    this.photo2Name = image.name;
    if (image.size < 1097152) {
      if (
        image.type.toLowerCase() === "image/gif" ||
        image.type.toString() === "image/jpeg"
      ) {
        this.photo2Error = "";
        const reader = new FileReader();
        reader.readAsDataURL(image);

        reader.onload = () => {
          this.photo2 = reader.result.toString();
          event.value = "";
        };

        reader.onerror = (error) => {
          this.loggerService.log("Error on Loading Image", error);
          this.photo2Error = "Error while Loading File";
        };
      } else {
        this.photo2Error = "Invalid File Type";
      }
    } else if (image.size > 1097152) {
      this.photo2Error = "File Size is greater than 1MB";
    } else if (
      image.type.toLowerCase() !== "gif" ||
      image.type.toString() !== "jpg"
    ) {
      this.photo2Error = "Invalid File Type";
    }
  }

  uploadPhoto3(event, files: FileList) {
    const image = files.item(0);
    this.photo3Name = image.name;
    if (image.size < 1097152) {
      if (
        image.type.toLowerCase() === "image/gif" ||
        image.type.toString() === "image/jpeg"
      ) {
        this.photo3Error = "";
        const reader = new FileReader();
        reader.readAsDataURL(image);

        reader.onload = () => {
          this.photo3 = reader.result.toString();
          event.value = "";
        };

        reader.onerror = (error) => {
          this.loggerService.log("Error on Loading Image", error);
          this.photo3Error = "Error while Loading File";
        };
      } else {
        this.photo3Error = "Invalid File Type";
      }
    } else if (image.size > 1097152) {
      this.photo3Error = "File Size is greater than 1MB";
    } else if (
      image.type.toLowerCase() !== "gif" ||
      image.type.toString() !== "jpg"
    ) {
      this.photo3Error = "Invalid File Type";
    }
  }

  uploadPhoto4(event, files: FileList) {
    const image = files.item(0);
    this.photo4Name = image.name;
    if (image.size < 1097152) {
      if (
        image.type.toLowerCase() === "image/gif" ||
        image.type.toString() === "image/jpeg"
      ) {
        this.photo4Error = "";
        const reader = new FileReader();
        reader.readAsDataURL(image);

        reader.onload = () => {
          this.photo4 = reader.result.toString();
          event.value = "";
        };

        reader.onerror = (error) => {
          this.loggerService.log("Error on Loading Image", error);
          this.photo4Error = "Error while Loading File";
        };
      } else {
        this.photo4Error = "Invalid File Type";
      }
    } else if (image.size > 1097152) {
      this.photo4Error = "File Size is greater than 1MB";
    } else if (
      image.type.toLowerCase() !== "gif" ||
      image.type.toString() !== "jpg"
    ) {
      this.photo4Error = "Invalid File Type";
    }
  }

  removePhoto1() {
    this.photo1Name = "";
    this.photo1 = null;
  }
  removePhoto2() {
    this.photo2Name = "";
    this.photo2 = null;
  }
  removePhoto3() {
    this.photo3Name = "";
    this.photo3 = null;
  }
  removePhoto4() {
    this.photo4Name = "";
    this.photo4 = null;
  }

  backButtonClicked() {
    this.inspectionService.setErrorInFireAndDamper(false)
    this.emitErrorMessages.emit([]);
    this.backButton.emit();
  }

  redirectDashboard() {

    this.inspectionService.setErrorInFireAndDamper(false)

    if (this.router.url.indexOf("fileCabinet") > -1 || this.router.url.indexOf("dashboardAuthority") > -1) {
      this.dialogRef.close();
      return;
    }
    
    if (!this.addEditProperty && !this.isViewMode) {
      this.router.navigate(
        [
          "user/inspection/searchInspectionProperties/getInspectionAssetsByProperty",
        ],
        {
          queryParams: {
            id: this.propertyId,
            pac: this.pac,
            isSimplifiedReportEnabled: this.isSimplifiedReportEnabled,
            isImageUploadEnabled: this.isImageUploadEnabled,
            fileCabinetRedirect: false,
          },
        }
      );
    } else if (this.isViewMode) {
      this.location.back();
    } else {
      this.router.navigate(
        ["user/inspection/addEditProperty/getInspectionAssetsByProperty"],
        {
          queryParams: {
            id: this.propertyId,
            pac: this.pac,
            isSimplifiedReportEnabled: this.isSimplifiedReportEnabled,
            isImageUploadEnabled: this.isImageUploadEnabled,
          },
        }
      );
    }
  }

  checkValidity(data, type, id, unit?, question?) {
    if (data !== null && typeof data === "object") {
      data = data[0];
    }
    if (data && data.trim() !== "") {
      const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      if (unit) {
        if (type === "Text") {
          if (data !== "N/A" && data.length < 1) {
            if (!this.invalidAnswers.find((x) => x.id === id)) {
              this.invalidAnswers.push({ id, type: "text" });
            }
            return false;
          } else {
            if (this.invalidAnswers.find((x) => x.id === id)) {
              this.invalidAnswers = this.invalidAnswers.filter(
                (x) => x.id !== id
              );
            }
            return true;
          }
        }
        if (type === "Date") {
          if (unit && unit.split("-") && unit.split("-").length === 3) {
            if (
              question.indexOf("Last cleaning date (per NFPA standards):") > -1
            ) {
              if (
                data != "N/A" &&
                (!this.validatePastDate(data) || !this.isValidDate(data))
              ) {
                if (!this.invalidAnswers.find((x) => x.id === id)) {
                  this.invalidAnswers.push({ id, type: "date" });
                }
                return false;
              } else if (this.validateFutureDate(data)) {
                if (!this.invalidAnswers.find((x) => x.id === id)) {
                  this.invalidAnswers.push({ id, type: "Futuredate" });
                }
                return false;
              } else {
                if (this.invalidAnswers.find((x) => x.id === id)) {
                  this.invalidAnswers = this.invalidAnswers.filter(
                    (x) => x.id !== id
                  );
                }
                return true;
              }
            } else {
              if (data != "N/A" && !this.isValidDate(data)) {
                if (!this.invalidAnswers.find((x) => x.id === id)) {
                  this.invalidAnswers.push({ id, type: "date" });
                }
                return false;
              } else {
                if (this.invalidAnswers.find((x) => x.id === id)) {
                  this.invalidAnswers = this.invalidAnswers.filter(
                    (x) => x.id !== id
                  );
                }
                return true;
              }
            }
          } else {
            if (data != "N/A" && !this.isValidCommertialDate(data)) {
              if (!this.invalidAnswers.find((x) => x.id === id)) {
                this.invalidAnswers.push({ id, type: "date" });
              }
              return false;
            } else {
              if (this.invalidAnswers.find((x) => x.id === id)) {
                this.invalidAnswers = this.invalidAnswers.filter(
                  (x) => x.id !== id
                );
              }
              return true;
            }
          }
        }
      } else {
        if (
          (question && question.indexOf("Tel#:(Office)") >= 0) ||
          question.indexOf("(Cell)") >= 0
        ) {
          if (data && !data.match(phoneRegex)) {
            this.invalidAnswers.push({ id, type: "text" });
            return false;
          } else {
            this.invalidAnswers = this.invalidAnswers.filter(
              (x) => x.id !== id
            );
            return true;
          }
        }
        if (type === "Text") {
          return true;
        }
      }
    } else {
      return true;
    }
  }

  validatePastDate(date) {
    return moment(date, "MM-DD-YYYY").isBefore(new Date());
  }
  validateFutureDate(date) {
    return moment(date, "MM-DD-YYYY").isAfter(new Date());
  }
  checkHydrantImage(images, position) {
    if (images) {
      return images.find((x) => x["imagePlacement"] === position)
        ? true
        : false;
    } else {
      return false;
    }
  }

  setValuesOfNA(questonID, target, question?) {
    if (target.checked) {
      this.qtd[questonID] = "N/A";
    } else {
      this.qtd[questonID] = "";
    }
    if (this.invalidAnswers.find((x) => x.id === questonID)) {
      this.invalidAnswers = this.invalidAnswers.filter(
        (x) => x.id === !questonID
      );
    }
    // if (target.checked) {
    //   if (question && question.questions && question.questions.question &&
    //     question.questions.question === 'Weight of 1st CO2 or Nitrogen cartridge:'
    //     || question.questions.question === 'Weight of 2nd CO2 or Nitrogen cartridge:'
    //     || question.questions.question === 'Weight of 3rd CO2 or Nitrogen cartridge:') {
    //     if (!this.sequence) {
    //       this.sequence = [];
    //       this.sequence.push(question.questions.sequence + 1);
    //     } else {
    //       this.sequence.push(question.questions.sequence + 1);
    //     }
    //   }
    // } else {
    //   if (this.sequence && this.sequence.indexOf(question.questions.sequence + 1) >= 0) {
    //     this.sequence.splice(this.sequence.indexOf(question.questions.sequence + 1), 1);
    //   }
    // }
  }
  setClass(classSelected: MatRadioChange) {
    if (classSelected.value === "class2") {
      if (confirm("Do you want to add a Fire Hose test ?")) {
        this.showHose = true;
      } else {
        this.showHose = false;
        this.inspectionService.setHoseCount(0);
        this.inspectionService.setHoseRows(null);
        Object.keys(this.qtd).forEach((key) => {
          if (key && key.indexOf("hose") > 0) {
            this.qtd[key] = null;
          }
        });
      }
    } else {
      this.showHose = false;
      this.inspectionService.setHoseCount(0);
      this.inspectionService.setHoseRows(null);
      Object.keys(this.qtd).forEach((key) => {
        if (key && key.indexOf("hose") > 0) {
          this.qtd[key] = null;
        }
      });
    }
  }

  findQuestionId(description?) {
    if (this.questions) {
      if (
        this.questions.filter((x) => x.questions["question"] === description) &&
        this.questions.filter((x) => x.questions["question"] === description)[0]
      ) {
        return this.questions.filter(
          (x) => x.questions["question"] === description
        )[0].questions.questionId;
      }
    }
    return 0;
  }
  uploadFileSimplified(event, files: FileList) {
    this.imagePlacement = true;
    const image = files.item(0);
    this.fileNameSimplified = image.name;
    if (image.size < 5 * 1024 * 1024) {
      if (
        image.type.toLowerCase() === "image/gif" ||
        image.type.toString() === "image/jpeg" ||
        image.type.toString() === "application/pdf"
      ) {
        this.imageUploadErrorSimplified = "";
        const reader = new FileReader();
        reader.readAsDataURL(image);

        reader.onload = () => {
          this.imageSimplified = reader.result.toString();
          event.value = "";
        };

        reader.onerror = (error) => {
          this.loggerService.log("Error on Loading Image", error);
          this.imageUploadErrorSimplified = "Error while Loading File";
        };
      } else {
        this.imageUploadErrorSimplified = "Invalid File Type";
      }
    } else if (image.size > 5 * 1024 * 1024) {
      this.imageUploadErrorSimplified = "File Size is greater than 5MB";
    } else if (
      image.type.toLowerCase() !== "gif" ||
      image.type.toString() !== "jpg" ||
      image.type.toString() !== "application/pdf"
    ) {
      this.imageUploadErrorSimplified = "Invalid File Type";
    }
  }

  displayCondition(questionId, options, questionType) {
    if (questionType !== "DASC") {
      let value = true;
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < options.length; i++) {
        if (this.qtd[`${questionId}` + `*o*` + `${options[i]["data"]}`]) {
          value = false;
          break;
        }
      }
      return value;
    } else {
      return false;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((x) => {
      x.unsubscribe();
    });
  }

  ngAfterViewChecked() {
    this.replacementValue = this.qtd[this.findQuestionId("Install:")]
      ? this.qtd[this.findQuestionId("Install:")] === "Replacement"
        ? true
        : false
      : false;
    if (!this.authRemarks && this.inspectionDataforSecondPage) {
      this.authRemarks = this.inspectionDataforSecondPage.authorityRemarks;
    }

    if (
      window.location.href.indexOf("reviewInspection") > 0 ||
      window.location.href.indexOf("printReport") > 0 ||
      window.location.href.indexOf("configureInspectionReport") > 0
    ) {
      if (window.location.href.indexOf("configureInspectionReport") > 0) {
        this.assetTypeid = this.inspectionService.getAssetTypeId();
      }
      if (this.inspectionDataforSecondPage) {
        this.sequence = this.inspectionDataforSecondPage.sequence;
        this.showHose = this.inspectionDataforSecondPage.showHose;
        this.showPRV = this.inspectionDataforSecondPage.showPRV;
        if (
          !this.attachment ||
          (this.attachment && this.attachment.length === 0)
        ) {
          if (this.inspectionDataforSecondPage.inspectionAttachment) {
            this.attachment =
              this.inspectionDataforSecondPage.inspectionAttachment;
          }
          if (this.attachment && this.attachment.length > 0) {
            this.attachment.forEach((x) => {
              if (x.imagePlacement === "Simple") {
                this.imagePlacement = true;
                this.imageSimplified = x.attachment;
                this.fileNameSimplified = x.imageName;
              }
              if (x.imagePlacement === "Main") {
                this.image = x.attachment;
                this.fileName = x.imageName;
              }
              if (
                x.imagePlacement !== "Main" &&
                x.imagePlacement !== "Simple" &&
                this.assetTypeid !== "14"
              ) {
                if (!this.photo1) {
                  this.photo1 = x.attachment;
                  this.photo1Name = x.imageName;
                  return;
                }
                if (!this.photo2) {
                  this.photo2 = x.attachment;
                  this.photo2Name = x.imageName;
                  return;
                }
                if (!this.photo3) {
                  this.photo3 = x.attachment;
                  this.photo3Name = x.imageName;
                  return;
                }
                if (!this.photo4) {
                  this.photo4 = x.attachment;
                  this.photo4Name = x.imageName;
                  return;
                }
              }
            });
          }
        }
        setTimeout(
          () =>
            (this.showSimplified =
              this.inspectionDataforSecondPage.inspectionType ===
              "SIMPLIFIED_REPORT"
                ? true
                : false)
        );
      }
    }
  }

  getBase64Image(img) {
    let canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    let ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    let dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

  getDownstreamValue() {
    if (
      this.qtd[this.findQuestionId("Downstream Process:")] === "DCVA" &&
      !this.downstreamOthersValue
    ) {
      this.downstreamTypeInit = "DCVA";
      this.downstreamOthersValue = false;
    } else if (
      this.qtd[this.findQuestionId("Downstream Process:")] === "RPBA" &&
      !this.downstreamOthersValue
    ) {
      this.downstreamTypeInit = "RPBA";
      this.downstreamOthersValue = false;
    } else if (
      this.qtd[this.findQuestionId("Downstream Process:")] === "PVBA" &&
      !this.downstreamOthersValue
    ) {
      this.downstreamTypeInit = "PVBA";
      this.downstreamOthersValue = false;
    } else if (
      this.downstreamOthersValue &&
      this.qtd[this.findQuestionId("Downstream Process:")] === "OTHERS"
    ) {
      this.downstreamTypeInit = "OTHER";
      this.downstreamOthersValue = true;
    } else if (
      this.qtd[this.findQuestionId("Others")] &&
      this.qtd[this.findQuestionId("Others")][0].trim() !== ""
    ) {
      this.downstreamTypeInit = "OTHER";
      this.downstreamOthersValue = true;
    }

    return this.downstreamTypeInit;
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnChanges(changes: any) {
  
    if(window.location.href.indexOf("configureInspectionReport") > 0){
      this.assetTypeid = this.inspectionService.getAssetTypeId();
      this.configureFireAndSmokeDamperAsset()
    }
    
    if (changes["isSimplified"] && changes["isSimplified"].currentValue) {
      this.showSimplified = changes["isSimplified"].currentValue;
    }

    if (this.questions) {
      const a = this.questions.filter(
        (res) => res.questions.questionType === "Additional"
      );

      this.additionalQuestionExist = a.length > 0;
    }

    if (changes["questions"] && changes["questions"].currentValue) {
      this.questions = [...changes["questions"].currentValue];
    }

    if(this.reviewInspection 
      && this.reviewInspectionReport != null 
      && this.reviewInspectionReport != null 
      && this.reviewInspectionReport.assetTypeId == '17'
      && this.reviewInspectionReport.questionModelVersion > 4){
      this.isDampnerTableQuestion = true 
    }
  }

  configureFireAndSmokeDamperAsset(){
    this.isDampnerTableQuestion = false;
    if (
      this.assetTypeid == "17" &&
      this.inspectionResult &&
      this.inspectionResult.questionModelVersion &&
      this.inspectionResult.questionModelVersion > 4
    ) {
      this.isDampnerTableQuestion = true;
    }

    if(this.configureReport && this.questions.length > 0 &&  this.questions[0]['assetType'] === 'Fire and Smoke Damper'){
      this.isDampnerTableQuestion = true;
    }
  }
}
