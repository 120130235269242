import { HttpResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../../../login/services/login.service";
import * as i2 from "@angular/router";
import * as i3 from "../loader/loader.service";
var AppHttpInterceptorService = /** @class */ (function () {
    function AppHttpInterceptorService(loginService, router, loaderService) {
        this.loginService = loginService;
        this.router = router;
        this.loaderService = loaderService;
        this.requests = [];
        this.headersToSkipCommonLoader = ['PropertyNotificationHeader', 'PropertyAssestsListDashboardHeader', 'RFAComplianceHeader', 'RFAInboxHeader', 'RFATaskHeader', 'AuthorityInboxHeader', 'AuthorityTaskHeader', 'AuthorityComplianceHeader'];
    }
    AppHttpInterceptorService.prototype.intercept = function (req, next) {
        var _this = this;
        var fullReqUrl = '';
        /** This checks for the request url.
         * If url already contains the base part then it skips to attach the base part to the request URL
         * or if not then it add the base URL part to the requested URL
         */
        var headers = req.headers;
        var isAnySkipHeaderExist = this.headersToSkipCommonLoader.some(function (header) {
            return headers.has(header);
        });
        if (!isAnySkipHeaderExist) {
            this.loaderService.show();
        }
        else {
            this.headersToSkipCommonLoader.forEach(function (header) {
                if (headers.has(header)) {
                    req = req.clone({
                        headers: req.headers.delete(header)
                    });
                }
            });
        }
        if (!(this.router.url.endsWith('login-form') ||
            this.router.url.endsWith('signUp') ||
            req.url.indexOf('secure.shippingapis.com') >= 0)) {
            var authToken = sessionStorage.getItem('token');
            // set the auth token;
            req = req.clone({
                headers: req.headers.set('Authorization', "Bearer " + authToken)
            });
        }
        if (req.url.indexOf('http://') > -1 || req.url.indexOf('https://') > -1) {
            if (!isAnySkipHeaderExist) {
                this.loaderService.show();
                this.addRequest(req);
            }
            return next.handle(req).pipe(map(function (res) {
                if (res instanceof HttpResponse) {
                    if (res && res.headers && res.headers.get('Authorization') !== null) {
                        sessionStorage.setItem('token', res.headers.get('Authorization').split(' ')[1]);
                    }
                }
                return res;
            }), catchError(function (err) { return _this.handlerError(err); }), finalize(function () {
                if (!isAnySkipHeaderExist) {
                    _this.removeRequest(req);
                }
                if (_this.requests.length === 0) {
                    _this.loaderService.hide();
                }
            }));
        }
        else {
            fullReqUrl = "" + environment.baseUrl + req.url;
        }
        // cloning the request for updating the url
        // const dupReq = req.clone({ url: fullReqUrl });
        /**
         * headers can be updated here
         * auth token can be added here to the request
         */
        // const authHeader = this.authService.getAuthToken();
        // const authToken = this.authService.getAuthorizationHeaderValue();
        // set the auth token
        // headers = headers.set('Authorization', authToken);
        // headers = headers.set('AuthorizationKey', authHeader)
        // const authReq = req.clone({ headers, url: fullReqUrl });
        // const authReq = req.clone({ headers, url: req.url });
        // return next.handle(authReq).pipe(
        //   map(res => res),
        //   catchError(err => this.handlerError(err))
        // );
    };
    AppHttpInterceptorService.prototype.removeRequest = function (req) {
        var i = this.requests.indexOf(req);
        if (i >= 0) {
            this.requests.splice(i, 1);
        }
    };
    AppHttpInterceptorService.prototype.addRequest = function (req) {
        var i = this.requests.indexOf(req);
        if (i < 0) {
            this.requests.push(req);
        }
    };
    /**
     * @description Handle Http error
     * @param err Error
     */
    AppHttpInterceptorService.prototype.handlerError = function (err) {
        this.loaderService.hide();
        // check for the status code
        switch (err.status) {
            case 401:
                /** TODO
                 * when status code is 401 redirect back to unauthorized page (i.e. Login)
                 * Show the error message using popup or snackbars
                 */
                // this.authService.gotoLogin();
                break;
            case 403:
                /** TODO
                 * when status code is 403 redirect back to unauthorized page (i.e. Login)
                 * Show the error message using popup or snackbars
                 */
                this.router.navigate(["login/login-form"]);
                break;
        }
        return throwError(err);
    };
    AppHttpInterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppHttpInterceptorService_Factory() { return new AppHttpInterceptorService(i0.ɵɵinject(i1.LoginService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.LoaderService)); }, token: AppHttpInterceptorService, providedIn: "root" });
    return AppHttpInterceptorService;
}());
export { AppHttpInterceptorService };
