import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var StatisticsService = /** @class */ (function () {
    function StatisticsService(http) {
        this.http = http;
        this.baseUrl = environment.baseUrl;
        this.jurisId$ = new BehaviorSubject(null);
        this.jurisName$ = new BehaviorSubject(null);
    }
    StatisticsService.prototype.getAuthorityStatistics = function () {
        var headers = new HttpHeaders({
            'PropertyNotificationHeader': 'True'
        });
        var url = this.baseUrl + "/authority/statistics";
        return this.http.get(url, { headers: headers });
    };
    StatisticsService.prototype.getRFAAuthorityStatistics = function (jurisIds) {
        var url = this.baseUrl + ("/rfa/statistics/" + jurisIds);
        return this.http.get(url);
    };
    StatisticsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StatisticsService_Factory() { return new StatisticsService(i0.ɵɵinject(i1.HttpClient)); }, token: StatisticsService, providedIn: "root" });
    return StatisticsService;
}());
export { StatisticsService };
