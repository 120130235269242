<mat-card class="example-card mat-elevation-z2">
  <mat-card-header class="pl-0">
    <mat-card-title>Statistics</mat-card-title>
    <div class="d-flex align-items-center">
      <div class="icon-container-right" *ngIf="loading">
          <span class="material-icons spin">loop</span>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content class="p-0">
    <div class="row m-0 pt-3">
      <div class="col-9 widget-heading">Total Active Systems</div>
      <div class="col-3 pl-0 pr-0 widget-heading d-flex justify-content-start" style="word-break: break-all;" *ngIf="authorityStatistics">
        {{authorityStatistics.totalActiveSystem}}</div>
    </div>
    <div class="row m-0 pt-3">
      <div class="col-9 widget-heading">Total Active Properties</div>
      <div class="col-3 pl-0 pr-0 widget-heading d-flex justify-content-start" style="word-break: break-all;" *ngIf="authorityStatistics">
        {{authorityStatistics.totalActiveProperties}}</div>
    </div>
    <div class="row m-0 pt-3">
      <div class="col-9 widget-heading">Properties Due in Next 30 Days</div>
      <div class="col-3 pl-0 pr-0 widget-heading d-flex justify-content-start" style="word-break: break-all;" *ngIf="authorityStatistics">
        {{authorityStatistics.propDueInNext30Days}}</div>
    </div>

    <div class="row m-0 pt-3">
      <div class="col-9 widget-heading">Total Inspection Companies</div>
      <div class="col-3 pl-0 pr-0 widget-heading d-flex justify-content-start" style="word-break: break-all;" *ngIf="authorityStatistics">
        {{authorityStatistics.totalInspectionCompanies}}</div>
    </div>
    <div class="row m-0 pt-3 pb-3">
      <div class="col-9 widget-heading">General Compliance</div>
      <div class="col-3 pl-0 pr-0 widget-heading d-flex justify-content-start" style="word-break: break-all;" *ngIf="authorityStatistics">
        {{authorityStatistics.generalCompliancePercent}}</div>
    </div>
  </mat-card-content>
</mat-card>

<!-- Authority Statistics is declared in shared module because
    of chances of cyclic dependenciyes between authority module and shared module -->
