import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { OwnerContactDetails, OwnerContactListResponse } from '../../../shared/models/owner.model';

@Injectable({
  providedIn: 'root'
})
export class AuthorityService {
  baseUrl = environment.baseUrl;
  authorityProperties = new BehaviorSubject<any>(null);
  authorityPrefenrences = new BehaviorSubject<any>(null);
  rfaBasicDetails = new BehaviorSubject<any>(null);
  rfaContactsDetails = new BehaviorSubject<any>(null);
  rfaSettingsDetails = new BehaviorSubject<any>(null);
  rfaJurisdictions = new BehaviorSubject<any>(null);
  enableEditMode = new BehaviorSubject(false);
  userDetails = null;
  filteredInspectorsList = new BehaviorSubject<string>(null);
  authorityPreviewFees = new BehaviorSubject(null);
  constructor(private http: HttpClient) {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
  }

  public getPropertyList() {
    const url = this.baseUrl + `/authority/properties`;
    return this.http.get<any[]>(url);
  }
  // *** For Dashboard performance Improvement **
  public getPropertyListV2() {
      const url = this.baseUrl + `/v2/authority/properties`;
      return this.http.get<any[]>(url);
  }
  
  public getPropertyAssestsList(propId : any) {
    const headers = new HttpHeaders({
      'PropertyAssestsListDashboardHeader': 'True' 
    });

    const url = this.baseUrl + `/authority/propertAssests/`+ propId;
    return this.http.get<any[]>(url,{ headers: headers });
  }
  
  public getAuthorityassignedConctactsPreferences() {
    const url = this.baseUrl + `/authority/isAssignedContacts`;
    return this.http.get<boolean>(url);
  }
  // ******** End ****

  public getAuthorityPreferences() {
    const url = this.baseUrl + `/authority/preferences`;
    return this.http.get<any>(url);
  }

  public getAuthorityPreferencesBasicRFA() {
    const url = this.baseUrl + `/rfa/prefernces/basicDetails`;
    return this.http.get<any>(url);
  }

  public getAuthorityPreferencesContactsRFA() {
    const url = this.baseUrl + `/rfa/preferences/contact`;
    return this.http.get<any>(url);
  }

  public getAuthorityPreferencesSettingsRFA() {
    const url = this.baseUrl + `/rfa/preferences/settings`;
    return this.http.get<any>(url);
  }

  public updateAuthorityPreferences(body) {
    const url = this.baseUrl + `/authority/preferences`;
    return this.http.put<any>(url, body);
  }

  public updateSelectedAssets(body, id) {
    const url = this.baseUrl + `/authority/selectedAsset/${id}`;
    return this.http.put<any>(url, body);
  }

  public updateAuthorityPreferencesBasicRFA(body) {
    const url = this.baseUrl + `/rfa/prefernces/basicDetails`;
    return this.http.put<any>(url, body);
  }

  public updateAuthorityPreferencesContactsRFA(body) {
    const url = this.baseUrl + `/rfa/preferences/contact`;
    return this.http.put<any>(url, body);
  }

  public updateAuthorityPreferencesSettingsRFA(body) {
    const url = this.baseUrl + `/rfa/preferences/settings`;
    return this.http.put<any>(url, body);
  }

  public updateSelectedjurisdictioncontactwithsystems(body) {
    const url = this.baseUrl + `/authority/preferences/selectedjurisdictioncontactwithsystems`;
    return this.http.put<any>(url, body);

  }

  public updateAssetsForSecondaryContact(body) {
    const url = this.baseUrl + `/authority/selectedAsset/secondaryContact`;
    return this.http.put<any>(url, body);
  }


  public getSelectedjurisdictioncontactwithsystems() {
    const url = this.baseUrl + `/authority/preferences/selectedjurisdictioncontactwithsystems`;
    return this.http.get<any>(url);

  }

  public getListOfJurisdictions() {
    const url = this.baseUrl + '/authority/preferences/listofjurisdictions';
    return this.http.get<any>(url);
  }

  public getListOfContacts() {
    const url = this.baseUrl + '/authority/preferences/listOfAuthorityContacts';
    return this.http.get<any>(url);
  }

  public getContactsForJurisdiction() {
    const url = this.baseUrl + '/authority/preferences/secondarycontacts';
    return this.http.get<any>(url);
    // return this.http.get<any>(url, { params: { jurisdictionIds} });
  }

  public getInspectorData() {
    const url = this.baseUrl + '/authority/preferences/certifiedinspector';
    return this.http.get<any>(url);
  }

  public getAccountingFees() {
    const url = this.baseUrl + '/authority/preferences/accountingfeesdetails';
    return this.http.get<any>(url);
  }

  public updateAccountingFees(body) {
    const url = this.baseUrl + '/authority/preferences/accountingfeesdetails';
    return this.http.put<any>(url, body);

  }

  public getLetterHead() {
    const url = this.baseUrl + '/authority/preferences/notificationtemplatenew';
    return this.http.get<any>(url, {
      params: {
        templateType: 'LetterHead',
        department: JSON.parse(localStorage.getItem('userDetails')).role.name === 'ROLE_AUTHORITY_WORX' ?
          'TEGRIS Worx' : 'TEGRIS Fire'
      }
    });
  }

  public updateLetterHead(body) {
    body['department'] = JSON.parse(localStorage.getItem('userDetails')).role.name === 'ROLE_AUTHORITY_WORX' ?
      'TEGRIS Worx' : 'TEGRIS Fire';
    const url = this.baseUrl + '/authority/jurisdiction/preferences/notificationtemplate';
    return this.http.put<any>(url, body);
  }

  public updateCustomReport(assetTypeId, body) {
    const url = this.baseUrl + `/question/${assetTypeId}`;
    return this.http.post<any>(url, body);
  }

  public createCustomReport(body) {
    const url = this.baseUrl + `/question`;
    return this.http.post<any>(url, body);
  }

  public deleteCustomReport(assetId) {
    const url = this.baseUrl + `/delete/asset/${assetId}`;
    return this.http.delete<any>(url);
  }

  public getCustomReportByAssetId(id, jid = this.userDetails.jurisdiction.id) {
    const url = this.baseUrl + `/question/${id}/${jid}`;
    return this.http.get(url);
  }


  public getCustomAssetIds() {
    const url = this.baseUrl + `/customasset`;
    return this.http.get(url);
  }

  public getFrequencies() {
    const url = this.baseUrl + `/code/inspectionfrequency`;
    return this.http.get(url);
  }

  public setPreference() {
    const url = this.baseUrl + `/setPreference`;
    return this.http.put(url, {});
  }

  public getOwners() {
    const url = this.baseUrl + `/owner/contactList`;
    return this.http.get(url);
  }

  public getOwnerList(limit, offset, searchString, isMobileUser) {
    const url = this.baseUrl + `/contactList/owner`;
    return this.http.get<OwnerContactListResponse>(url, {
      params: {
        offset: offset,
        limit: limit,
        searchString: searchString,
        isMobileUser: isMobileUser
      }
    });
  }

  public getInspectors(limit, offset, searchString, isMobileUser) {
    const url = this.baseUrl + `/inspector/contactList`;
    return this.http.get<any>(url, {
      params: {
        offset: offset,
        limit: limit,
        searchString: searchString,
        isMobileUser: isMobileUser
      }
    });
  }

  public sendBulkNotification( data) {
    const url = this.baseUrl + `/bulkNotification`;
    return this.http.post<any>(url, data);
  }

  public getInspectionCompanyList() {
    const url = this.baseUrl + `/authority/preferences/inspectioncompanies`;
    return this.http.get<any[]>(url);
  }

  public getAdminInspectionCompanies() {
    const url = `${this.baseUrl}/admin/inpectioncompanies`;
    return this.http.get<any[]>(url);
  }

  public getCertifiedInspectorsForACompany(inspectionCompanyId) {
    const url = this.baseUrl + `/authority/preferences/certifiedinspectors/${inspectionCompanyId}`;
    return this.http.get<any[]>(url);
  }

  public getRFAJurisdication() {
    const url = this.baseUrl + `/rfa/jurisdictions`;
    return this.http.get<any[]>(url);
  }

  public getPropertyRFAList(jurisIds) {
    const url = this.baseUrl + `/rfa/dashboard/properties/${jurisIds}`;
    return this.http.get<any[]>(url);
  }

  public getBulkMailForInspector() {
    const url = this.baseUrl + `/inspector/contactList/bulkEmail`;
    return this.http.get<any[]>(url);
  }

  public getBulkMailForOwner() {
    const url = this.baseUrl + `/contactList/owner/bulkEmail`;
    return this.http.get<any[]>(url);
  }

  public getBulkMailForManager() {
    const url = this.baseUrl + `/contactList/manager/bulkEmail`;
    return this.http.get<any[]>(url);
  }

  public getContactListExportData(role, searchString) {
    const url = this.baseUrl + `/user/contact/export`;
    return this.http.get<any>(url, {
      params: {
        role: role,
        searchString: searchString
      }
    })
  }

}
