import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { environment } from "../../../environments/environment";
import { GetInspectorPref } from "../models/inspector.model";
import { PaymentReport } from "../models/inspector.model";
import { ListData } from "../../core/models/file-cabinet/file-cabinet.model";
import { tap, map } from "rxjs/operators";


@Injectable({
  providedIn: "root",
})
export class InspectorService {
  baseUrl = environment.baseUrl;
  formInvalid = new BehaviorSubject(false);
  constructor(private http: HttpClient) {}

  // downloadPDF(url): any {
  //   // const options = { responseType: ResponseContentType.Blob  };
  //   return this.http.get<Blob>(url,  { headers: new HttpHeaders({
  //     // 'Content-Type': 'application/pdf',
  //     responseType: 'blob'
  //     })
  //   }).pipe(
  //     map(
  //       (res) => {
  //         return new Blob([res['blob']()],
  //         //  { type: 'application/octet-stream' }
  //          { type: 'application/pdf' }
  //          );
  //     },
  //       error => console.log(error)
  //       )
  //   );
  // }
  isBidOpportunityApiLoaded: boolean = false;
  public bidOpportunities = new BehaviorSubject<any>([]);

  getInspectorPreferences(): Observable<GetInspectorPref> {
    const url = `${this.baseUrl}/inspector/preferences/companydetails`;
    return this.http.get<GetInspectorPref>(url);
  }

  putInspectorPreferences(companydetails): Observable<GetInspectorPref> {
    const url = `${this.baseUrl}/inspector/preferences/companydetails`;
    return this.http.put<GetInspectorPref>(url, companydetails);
  }

  getInspectorContacts(): Observable<any> {
    const url = this.baseUrl + `/inspector/preferences/contactdetails`;
    return this.http.get<any>(url);
  }

  getStates(): Observable<any> {
    const url = this.baseUrl + `/state`;
    return this.http.get<any>(url);
  }
  // getCounties(stateId): Observable<any> {
  //   const url = this.baseUrl + `/county/${stateId}`;
  //   return this.http.get<any>(url);
  // }

  public putInspectorContacts(contacts: any) {
    const url = this.baseUrl + `/inspector/preferences/contactdetails`;
    return this.http.put<any>(url, contacts);
  }

  public getPendingApprovalPropertyCount() {
    const url = this.baseUrl + "/inspector/preferences/countofPAA";
    return this.http.get<any>(url);
  }

  public getInspectionSnapshot() {
    const url = this.baseUrl + "/dashboard/placeholders";
    return this.http.get<any>(url);
  }

  public getInspectionInboxSnapshot() {
    const headers = new HttpHeaders({
      'AuthorityInboxHeader': 'True' 
    });
  
    const url = this.baseUrl + "/dashboard/inboxPlaceholders";
    return this.http.get<any>(url, { headers: headers });
  }

  public getInspectionTaskSnapshot() {
    const headers = new HttpHeaders({
      'AuthorityTaskHeader': 'True' 
    });
    const url = this.baseUrl + "/dashboard/taskPlaceholders";
    return this.http.get<any>(url, { headers: headers });
  }

  public getInspectionComplianceSnapshot() {
    const headers = new HttpHeaders({
      'AuthorityComplianceHeader': 'True' 
    });
    const url = this.baseUrl + "/dashboard/compliancePlaceholders";
    return this.http.get<any>(url, { headers: headers });
  }

  public getRFAInboxSnapshot(jurisdictionId) {
    const headers = new HttpHeaders({
      'RFAInboxHeader': 'True' 
    });
  
    const url = this.baseUrl + `/dashboard/RFA/placeholders/inbox/${jurisdictionId}`;
    return this.http.get<any>(url, { headers: headers });
  }

  public getRFATaskSnapshot(jurisdictionId) {
    const headers = new HttpHeaders({
      'RFATaskHeader': 'True' 
    });
    const url = this.baseUrl + `/dashboard/RFA/placeholders/Task/${jurisdictionId}`;
    return this.http.get<any>(url, { headers: headers });
  }

  public getRFAComplianceSnapshot(jurisdictionId) {
    const headers = new HttpHeaders({
      'RFAComplianceHeader': 'True' 
    });
    const url = this.baseUrl + `/dashboard/RFA/placeholders/Compliance/${jurisdictionId}`;
    return this.http.get<any>(url, { headers: headers });
  }



  public getRFAInspectionSnapshot(jurisdictionId) {
    const url = this.baseUrl + `/dashboard/RFA/placeholders/${jurisdictionId}`;
    return this.http.get<any>(url);
  }

  public putInspectorPreferencesSettings(settings: any) {
    const url = this.baseUrl + `/inspector/preferences/settings`;
    return this.http.put<any>(url, settings);
  }

  public getInspectorSettings() {
    const url = this.baseUrl + `/inspector/preferences/settings`;
    return this.http.get<any>(url);
  }

  public getLetterHead() {
    const url = this.baseUrl + "/inspector/preferences/notificationtemplate";
    return this.http.get<any>(url);
  }

  public updateLetterHead(body) {
    const url = this.baseUrl + "/inspector/preferences/notificationtemplate";
    return this.http.put<any>(url, body);
  }

  public deleteLetterHead(id) {
    const url =
      this.baseUrl + `/inspector/preferences/notificationtemplate/${id}`;
    return this.http.delete<any>(url);
  }

  // -----start
  // storing response of this api in a subject to avoid duplicate api calls
  private loadBidOpportunities = (): void => {
    const url = this.baseUrl + "/bidsNew/opportunities/inspector";
    setTimeout(() => {
      this.http.get<any>(url).subscribe(
        (res) => {
          this.bidOpportunities.next(res);
        },
        (err) => (this.isBidOpportunityApiLoaded = false)
      );
    }, 1000);
  };

  public setBidOpportunities(bidOpportunities): void {
    this.bidOpportunities.next(bidOpportunities);
  }

  public getBidOpportunities(): Observable<any> {
    if (
      this.bidOpportunities.getValue().length === 0 &&
      !this.isBidOpportunityApiLoaded
    ) {
      this.isBidOpportunityApiLoaded = true;
      this.loadBidOpportunities();
    }

    return this.bidOpportunities.asObservable();
  }
  // -----end

  public getLatestBidOpportunity() {
    const url = this.baseUrl + `/recentBids/properties`;
    return this.http.get<any>(url);
  }

  public sendBid(body) {
    const url = this.baseUrl + "/bid";
    return this.http.post<any>(url, body);
  }

  getInspectionCompanies() {
    const url = this.baseUrl + "/inspectionCompanyList";
    return this.http.get<ListData[]>(url);
  }

  getPropertyAssetsStatus(status) {
    const url = this.baseUrl + `/property/details/${status}`;
    return this.http.get<any>(url);
  }

  changeToSubmittedForReview(params = {}) {
    const url = this.baseUrl + `/property/confirmPayment`;
    return this.http.post<any>(url, {}, { params });
  }

  getPropertyStatistics() {
    const url = this.baseUrl + `/property/statistics`;
    return this.http.get<any>(url);
  }

  public setPreference() {
    const url = this.baseUrl + `/setPreference`;
    return this.http.put(url, {});
  }

  getListOfAHJsByInspectorId(id: string, searchKey: string) {
    const url = this.baseUrl + `/authority/${id}/${searchKey}`;
    return this.http.get<any>(url);
  }

  getListOfWorXByInspectorId(id: string, searchKey: string) {
    const url = this.baseUrl + `/authorityworkx/${id}/${searchKey}`;
    return this.http.get<any>(url);
  }

  getpaymentReportsByDateRange(
    fromDate: string,
    toDate: string
  ): Observable<PaymentReport[]> {
    const url = this.baseUrl + `/inspector/paymentreports`;
    return this.http.get<PaymentReport[]>(url, {
      params: { fromDate, toDate },
    });
  }
}
